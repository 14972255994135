import request from '@/utils/request'



// 查询参数列表
export function pageConfig (query) {
  return request({
    url: '/system/configs/page',
    method: 'get',
    params: query
  })
}

// 查询参数列表
export function queryByConfigGroup (query) {
  return request({
    url: '/system/configs/queryByConfigGroup',
    method: 'get',
    params: query
  })
}

// 查询参数列表
export function updateByConfigGroup (query) {
  return request({
    url: '/system/configs/updateByConfigGroup',
    method: 'put',
    data: query
  })
}

// 查询参数列表
export function listConfig (query) {
  return request({
    url: '/system/configs/list',
    method: 'get',
    params: query
  })
}

// 查询参数详细
export function getConfig (configId) {
  return request({
    url: '/system/configs/' + configId,
    method: 'get'
  })
}

// 根据参数键名查询参数值
export function getConfigKey (configKey) {
  return request({
    url: '/system/configs/configKey/' + configKey,
    method: 'get'
  })
}

// 新增参数配置
export function addConfig (data) {
  return request({
    url: '/system/configs',
    method: 'post',
    data: data
  })
}

// 修改参数配置
export function updateConfig (data) {
  return request({
    url: '/system/configs',
    method: 'put',
    data: data
  })
}

// 删除参数配置
export function delConfig (configId) {
  return request({
    url: '/system/configs/' + configId,
    method: 'delete'
  })
}

// 刷新参数缓存
export function refreshCache () {
  return request({
    url: '/system/configs/refreshCache',
    method: 'delete'
  })
}
